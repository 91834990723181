import React from "react";
import {isIOS, isMobile, isAndroid } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
const Connect = () => {

    const navigate = useNavigate();
    React.useEffect(() => {

        if(isMobile) {
            if(isAndroid) {
                window.location.replace('https://play.google.com/store/apps/details?id=com.blizzup.android');
            }
            if(isIOS) {
                window.location.replace('https://apps.apple.com/ro/app/blizzup-make-friends-party/id1613937164');
            }
        } else {
            navigate('/', {
                replace: true
            });
        }
    },[])

    return (
        <div style={{
            width:'100%',
            height:'100%',
            backgroundColor:'#151515'
        }}>
        </div>
    );
};

export default Connect;
