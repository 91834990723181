import React from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Helmet } from "react-helmet";

// Components
import Topbar from "./components/Topbar";
import Bottombar from "./components/Bottombar";
// Pages

import Home from "./Home";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Data from "./screens/help/Data";
import Onboarding from "./screens/help/Onboarding";
import Tickets from "./screens/help/Tickets";
import Payments from "./screens/help/Payments";
import Taxes from "./screens/help/Taxes";
import Refunds from "./screens/help/Refunds";
import Earnings from "./screens/help/Earnings";
import Terms from "./screens/Terms";
import CommunityGuidelines from "./screens/CommunityGuidelines";
import Help from "./screens/Help";
import SupportForm from "./screens/SupportForm";
import Contact from "./screens/Contact";
import Verified from "./screens/Verified";
import PasswordInfo from "./screens/PasswordInfo";
import ResetPassword from "./screens/ResetPassword";
import UserManagement from "./components/controllers/UserManagement";
import Connect from "./screens/Connect";
import TikTok from "./screens/TikTok";
import Download from "./screens/Download";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/download" element={<Connect />} />
        <Route path="/gettheapp" element={<TikTok />} />
        {/* <Route path="/support-form" element={<SupportForm/>} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/help" element={<Help />} />
        <Route path="/community-guidelines" element={<CommunityGuidelines />} />
        <Route path="/download" element={<Download />} />

        {/* Help Routes */}
        <Route path="/help" element={<Help />} />
        <Route path="/help/data" element={<Data />} />
        <Route path="/help/onboarding" element={<Onboarding />} />
        <Route path="/help/tickets" element={<Tickets />} />
        <Route path="/help/payments" element={<Payments />} />
        <Route path="/help/taxes" element={<Taxes />} />
        <Route path="/help/refunds" element={<Refunds />} />
        <Route path="/help/earnings" element={<Earnings />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/user/manage" element={<UserManagement />} />
        {/* TODO: 404 Page not found screen */}
        {/* <Route path={"*"} element={</>} /> */}
      </Routes>
      <Helmet>
        <script>
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1751227301997046');
fbq('track', 'PageView');
`}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=1751227301997046&ev=PageView&noscript=1"
          />`}
        </noscript>
        <script>
{`!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

  ttq.load('CK9JA7JC77U1O76T69VG');
  ttq.page();
}(window, document, 'ttq');`}
</script>
      </Helmet>
    </div>
  );
}

export default App;
