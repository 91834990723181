import React, { Component } from "react";
// Screens
import FirstScreen from "./screens/FirstScreen";
// Components
import Topbar from "./components/Topbar";
import Bottombar from "./components/Bottombar";

class Home extends Component {
  render() {
    return (
      <div>
        <Topbar />
        <FirstScreen />
        {/* <SecondScreen /> */}
        <Bottombar />
        <div className="copyrightContainer">
          <span className="copyright">Copyright © {new Date().getFullYear()} Blizzup</span>
        </div>
      </div>
    );
  }
}

export default Home;
