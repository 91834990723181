import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

const Download = ({}) => {
  useEffect(() => {
    if (isIOS) {
      window.location.replace(process.env.REACT_APP_APP_STORE_LINK);
    } else if (isAndroid) {
      window.location.replace(process.env.REACT_APP_GOOGLE_PLAY_LINK);
    } else {
      window.location.replace("");
    }
  });

  return (
    <div className="download_container">
      <p className="title1">Redirecting...</p>
    </div>
  );
};

export default Download;
