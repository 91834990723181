import React from "react";
import Topbar from "../../components/Topbar";

const Refunds = () => {
  return (
    <div>
      <Topbar />
      <div className="termsContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Refunds</h1>
        </div>
        <div className="innerTerms">
          <h1 className="termsHeading">Our action</h1>
          <p className="termsText">
            • The refund will be made if a paid party/crowdfund party is canceled
            by the host or in the situation where we decide that the party
            violates our terms. Processing fees will be lost
            <br />
            • Refunds are made within 10-15 working days after they are initiated
          </p>
        </div>
      </div>
    </div>
  );
};

export default Refunds;
