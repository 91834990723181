import React from "react";
import { useSearchParams } from "react-router-dom";
import DeletedAccount from "../../screens/DeletedAccount";
import ResetPassword from "../../screens/ResetPassword";
import Verified from "../../screens/Verified";

const extractParams = (searchParams) => ({
  mode: searchParams.get("mode"),
  oobCode: searchParams.get("oobCode"),
  apiKey: searchParams.get("apiKey"),
  continueUrl: searchParams.get("continueUrl"),
  lang: searchParams.get("lang"),
});

const UserManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = extractParams(searchParams);

  switch (params.mode) {
    case "resetPassword": {
      return <ResetPassword {...params} />;
    }
    case "verifiedAccount": {
      return <Verified />;
    }
    case "deletedAccount": {
      return <DeletedAccount />;
    }
    default:
      return null;
  }
};

export default UserManagement;
