import React from "react";
import Topbar from "../../components/Topbar";

const Earnings = () => {
  return (
    <div>
      <Topbar />
      <div className="termsContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Earnings</h1>
          <p className="info3">
            This is how you can manage your Blizzup earnings.
          </p>
        </div>
        <div className="innerTerms">
          <h1 className="termsHeading">Balance</h1>
          <p className="termsText">
            Balance reflects active earnings before payout. You can see the balance by navigating to
            <b> {" Profile > Wallet (wallet icon) > Wallet or Settings > Joining & hosting > Wallet "}</b>.
          </p>
          <h1 className="termsHeading">Payout</h1>
          <p className="termsText">
            Payouts represent the earnings that were transferred automatically to bank account after the party ended successfully.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
