import React, { Component } from "react";
const topbar_logo = require("../images/Topbar_logo.png");

class Topbar extends Component {
  render() {
    return (
      <div className="topbarContainer">
        <div className="innerTopbar">
          <img src={topbar_logo} className="topbarLogo" alt="blizzup logo" />
          {/* <div className="contactBtnContainer" />
          <Link to="/contact" className="contactBtn">
            Contact
          </Link> */}
        </div>
      </div>
    );
  }
}

export default Topbar;
