import React from "react";
import Topbar from "../../components/Topbar";

const Onboarding = () => {
  return (
    <div>
      <Topbar />
      <div className="termsContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Creating & attending a party</h1>
          <p className="info3">
            This is how you can create a party or joine one.
          </p>
        </div>
        <div className="innerTerms">
          <h1 className="termsHeading">Create a party</h1>
          <p className="termsText">
            You can create a party by navigating to
            <b> {" Home > + New"}</b> or
            <b>{" Home > Map > + New Party"}</b>.
          </p>
          <h1 className="termsHeading">Edit your party</h1>
          <p className="termsText">
            As a host you can edit your party by navigating to
            <b>{" Home > My Parties > Your party > Edit Party (edit icon) "}</b>
            or just find your party in any list containing parties and repeat
            the process.
          </p>
          <h1 className="termsHeading">Cancel your party</h1>
          <p className="termsText">
            As a host you can cancel your party by navigating to
            <b>
              {
                " Edit Party (edit icon) > Cancel Party "
              }.
            </b>
          </p>
          <h1 className="termsHeading">Join a party</h1>
          <p className="termsText">
            Navigate to any party page from any list containing parties and
            press the following button
            <b>{" Join / Buy Ticket / Accept to Join"}</b> from the bottom of
            the screen.
          </p>
          <h1 className="termsHeading">Leave a party</h1>
          <p className="termsText">
            As a host you can't leave a party. As a joiner you can do this only to free parties by
            pressing the <b>{"Leave Party"}</b> button from the bottom of the
            screen.
          </p>
          {/* <h1 className="termsHeading">By Submitting a Support Ticket</h1>
          <p className="termsText">
            You can submit a support ticket using our website to delete your
            data, retrieve its contents and more.
          </p>
          <h1 className="termsHeading">By Sending Us An Email</h1>
          <p className="termsText">
            You can contact us at <PrivacyMail /> for further help regarding
            data and privacy matters.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
