import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Topbar2 from "../components/Topbar2";
import PasswordInfo from "./PasswordInfo";
import { initializeApp } from "@firebase/app";
import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from "@firebase/auth";
import { isAndroid, isIOS } from "react-device-detect";

const checkPasswordStrength = (text = "") => {
  let regex = /(?=.*[0-9])(?=.{8,})(?=.*[a-zA-Z])/;
  return text.length >= 8 && regex.test(text);
};

const ResetPassword = ({ oobCode, continueUrl, apiKey }) => {
  const navigate = useNavigate();

  const emailSent = useRef(false);

  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [tooWeak, setTooWeak] = useState(false);
  const [mismatch, setMismatch] = useState(false);
  const [success, setSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);
  const [email, setEmail] = useState(null);

  const firApp = useRef(null);
  const authInstance = useRef(null);

  useEffect(() => {
    firApp.current = initializeApp({ apiKey: apiKey });
    authInstance.current = getAuth(firApp.current);

    verifyPasswordResetCode(authInstance.current, oobCode)
      .then((email) => {
        setEmail(email);
        setTokenValid(true);
      })
      .catch((err) => {
        console.log("verify token: ", err);
        setTokenValid(false);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const submit = useCallback(() => {
    console.log("submitting");

    if (!firApp.current) {
      firApp.current = initializeApp({ apiKey: apiKey });
    }
    if (!authInstance.current) {
      authInstance.current = getAuth(firApp.current);
    }

    if (!checkPasswordStrength(password)) {
      setTooWeak(true);
    } else if (password !== repeat) {
      setTooWeak(false);
      setMismatch(true);
    } else {
      setTooWeak(false);
      setMismatch(false);

      confirmPasswordReset(authInstance.current, oobCode, password)
        .then((res) => {
          setSuccess(true);
        })
        .catch((err) => alert("An error has occured, please try again later"))
        .finally(() => {
          if (isAndroid || isIOS) {
            setTimeout(() => window.location.replace("blizzup://login"), 3000);
          }
        });
    }
  }, [
    password,
    repeat,
    setMismatch,
    setPassword,
    setTooWeak,
    setRepeat,
    navigate,
  ]);

  useEffect(() => {
    if (success && !emailSent.current && email) {
      fetch(
        `${process.env.REACT_APP_API_URL}/auth/forgotPassword/confirmation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      ).then((res) => {
        if (res.ok) {
          emailSent.current = true;
        } else {
          console.log("error confirming pass change", res.statusText);
        }
      });
    }
  }, [success]);

  if (success) {
    return <PasswordInfo />;
  }

  return (
    <div>
      <Topbar2 />
      <div className="firstScreenContainer">
        <h1 className="termsTitle">Reset your password</h1>
        <div className="inner3">
          {isLoading ? (
            <CircularProgress />
          ) : tokenValid ? (
            <>
              <h1 className="nameHeading">New password</h1>
              <input
                type="password"
                placeholder="Type your password..."
                className="inputName"
                onChange={(event) => setPassword(event.target.value)}
              />
              <h1 className="descriptionInfo">
                Password must contain at least 8 characters and be a combination
                of letters and numbers.
              </h1>
              {tooWeak ? (
                <h1 className="passwordError">
                  Password is too weak. Use a combination of letters and
                  numbers.
                </h1>
              ) : null}
              <h1 className="emailHeading">Re-type new password</h1>
              <input
                type="password"
                placeholder="Re-type your password..."
                className="inputEmail"
                onChange={(event) => setRepeat(event.target.value)}
              />
              {mismatch ? (
                <h1 className="passwordError">Passwords do not match.</h1>
              ) : null}
              <button
                to="/PasswordInfo"
                className="btn submit"
                onClick={submit}
              >
                Submit
              </button>
            </>
          ) : (
            <h1 className="nameHeading">
              {" "}
              Password reset link expired or invalid. Please try again
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
