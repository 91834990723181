import React from "react";
import Topbar from "../../components/Topbar";

const Taxes = () => {
  return (
    <div>
      <Topbar />
      <div className="termsContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Taxes</h1>
          <p
            className="info3"
            style={{
              color: "red",
              backgroundColor: "#e8e8e8",
              padding: 20,
              borderRadius: 10,
            }}
          >
            Blizzup does not assume and is not responsible for the taxes that
            each user has to pay as a result of the income generated from the
            events created.
          </p>
        </div>
        <div className="innerTerms">
          <h1 className="termsHeading">Taxes</h1>
          <p className="termsText">
            Before organizing a paid party & crowdfund, it is recommended that
            you consider the local fees you have to pay.
          </p>
          <h1 className="termsHeading">Hosting</h1>
          <p className="termsText">
            • To host a paid party/crowdfund party you must create a Stripe
            payout account
            <br />
            • The payout account is an automatically generated account required
            by Stripe in order to be able to transfer the earnings made out of
            ticket selling
            <br />• The following commissions will be charged by Stripe for the
            payout account:
            <br/>
            <a href="https://stripe.com/connect/pricing" className="stripeText">-> Stripe docs</a>
          </p>

          <table>
            <tr>
              <th scope="col">Currency</th>
              <th scope="col">per monthly active account</th>
              <th scope="col">per payout sent</th>
            </tr>
            <tr>
              <th scope="row">RON</th>
              <td>10 RON</td>
              <td>0.25% + 1.50 RON</td>
            </tr>
            <tr>
              <th scope="row">EUR</th>
              <td>2 EUR</td>
              <td>0.25% + 0.10 EUR</td>
            </tr>
            <tr>
              <th scope="row">USD</th>
              <td>2 USD</td>
              <td>0.25% + 0.25 USD</td>
            </tr>
            <tr>
              <th scope="row">GBP</th>
              <td>2 GBP</td>
              <td>0.25% + 0.10 GBP</td>
            </tr>
            <tr>
              <th scope="row">CHF</th>
              <td>2 CHF</td>
              <td>0.25% + 0.55 CHF</td>
            </tr>
            <tr>
              <th scope="row">CAD</th>
              <td>2 CAD</td>
              <td>0.25% + 0.25 CAD</td>
            </tr>
            <tr>
              <th scope="row">AUD</th>
              <td>2 AUD</td>
              <td>0.25% + 0.25 AUD</td>
            </tr>
          </table>

          <h1 className="termsHeading">Fees & commissions</h1>
          <p className="termsText">
            • The fee for tickets sold and crowdfund earnings is 5.9% +
            commission that differs depending on the currency in which the
            ticket/crowdfund was created:
          </p>

          <table>
            <tr>
              <th scope="col">Currency</th>
              <th scope="col">Fee</th>
              <th scope="col">Commission/item</th>
            </tr>
            <tr>
              <th scope="row">RON</th>
              <td>5.9%</td>
              <td>1 RON</td>
            </tr>
            <tr>
              <th scope="row">EUR</th>
              <td>5.9%</td>
              <td>0.25 EUR</td>
            </tr>
            <tr>
              <th scope="row">USD</th>
              <td>5.9%</td>
              <td>0.30 USD</td>
            </tr>
            <tr>
              <th scope="row">GBP</th>
              <td>5.9%</td>
              <td>0.20 GBP</td>
            </tr>
            <tr>
              <th scope="row">CHF</th>
              <td>5.9%</td>
              <td>0.30 CHF</td>
            </tr>
            <tr>
              <th scope="row">CAD</th>
              <td>5.9%</td>
              <td>0.30 CAD</td>
            </tr>
            <tr>
              <th scope="row">AUD</th>
              <td>5.9%</td>
              <td>0.40 AUD</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Taxes;
