import React, { Component } from "react";
import { Link } from "react-router-dom";
const topbar_logo = require("../images/Topbar_logo2.png");

class Topbar extends Component {
  render() {
    return (
      <div className="topbarContainer2">
        <div className="innerTopbar">
          <img src={topbar_logo} className="topbarLogo" alt="blizzup logo" />
          <div className="contactBtnContainer" />
        </div>
      </div>
    );
  }
}

export default Topbar;
