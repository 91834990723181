import React, { Component } from "react";
import Topbar2 from "../components/Topbar2";

const verified_logo = require("../images/Topbar_logo2.png");

class PasswordInfo extends Component {
  render() {
    return (

      <div>
        <Topbar2/>
        <div className="inner4">
          <img src={verified_logo} className="verifiedLogo" />
          <h1 className="termsTitle">Your password has been changed successfully!</h1>
          <h1 className="termsText">
            You can Log In to your Blizzup account.
          </h1>
        </div>
      </div>
    );
  }
}

export default PasswordInfo;

