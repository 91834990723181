import React from "react";
import Topbar from "../../components/Topbar";
import PrivacyMail from "../../links/PrivacyMail";

const Data = () => {
  return (
    <div>
      <Topbar />
      <div className="termsContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Data help</h1>
          <p className="info3">
            This is how you can retrieve, delete or manage your personal
            information stored and processed by Blizzup.
          </p>
        </div>

        <div className="innerTerms">
          <h1 className="termsHeading">In the app</h1>
          <p className="termsText">
            You can delete your account/data by navigating to
            <b> {" Profile > Settings > Account "}</b> and choosing "Close
            Account".
          </p>
          <h1 className="termsHeading">By sending us an email</h1>
          <p className="termsText">
            You can contact us at <PrivacyMail /> for further help regarding
            data and privacy matters.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Data;
