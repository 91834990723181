import React, {Component} from "react";
import Topbar2 from "../components/Topbar2";

class Terms extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Topbar2/>
                <div className="termsContainer">
                    <h1 className="termsTitle">Blizzup Terms of Service</h1>
                    <div className="innerTerms">
                        <h1 className="termsHeading">1. INTRODUCTION</h1>
                        <h1 className="termsText">
                            1.1. Thank you for choosing to use the service provided by Blizzup
                            (hereinafter referred to as "the Service"). By creating an account
                            in the Blizzup application, you agree to the Terms and Conditions
                            of use of the platform. Your use of the Service is subject to
                            these provisions (collectively, the "Agreement"). Please read this
                            Agreement carefully and make sure you understand it. If you do not
                            understand the Agreement or do not accept any part of it, then you
                            may not use the Service. <br/> 1.2. We also reserve the right to
                            make changes to the Services or the User Agreement without prior
                            notice. However, we will do our best to notify you of important
                            changes 30 (thirty) days prior to their entry into force. If you
                            continue to use the Services after any changes, we will assume
                            that you have accepted the changes to the Agreement. <br/> 1.3.
                            The Service is offered by WEAREBLIZZUP SRL, fiscal code 45597945
                        </h1>

                        <h1 className="termsHeading">2. ABOUT US</h1>
                        <h1 className="termsText">
                            2.1. Blizzup is a social media service that aims to connect people
                            with cultural events of their choice and to allow them to share
                            photos and videos in a safe and friendly environment. The services
                            we offer are:
                            <ul>
                                <li>
                                    Possibility to create a personalized account within the
                                    Service;
                                </li>
                                <li>
                                    Features that allow you to upload and share videos and photos
                                    of your choice;
                                </li>
                                <li>
                                    Connecting you with the clubs, parties and cultural events of
                                    your choice;
                                </li>
                                <li>
                                    Interacting with people that share similar interest and/or
                                    events;
                                </li>
                            </ul>
                        </h1>

                        <h1 className="termsHeading">
                            3. Rights and obligations associated with your use of the service
                        </h1>
                        <h1 className="termsText">
                            3.1. The service can be used in their own interest by individuals
                            who have reached the age of 18 at the time of concluding the
                            Contract and who create an account on Blizzup or legal entities.
                            <br/>
                            3.2. In addition to that you need to observe the following
                            obligations:
                            <ul>
                                <li>
                                    We must not have previously disabled your account for
                                    violation of law or any of our policies.
                                </li>
                                <li>
                                    You can't impersonate others or provide inaccurate,
                                    misleading, false information about yourself.
                                </li>
                                <li>
                                    You can't do anything unlawful, misleading, or fraudulent or
                                    for an illegal or unauthorized purpose.
                                </li>
                                <li>
                                    You can't violate (or help or encourage others to violate)
                                    these Terms
                                </li>
                                <li>
                                    If you post branded content, you must comply with our Branded
                                    Content Policies, which require you to use our branded content
                                    tool. Learn how to report conduct or content in our Help
                                    Center.
                                </li>
                                <li>
                                    You can't do anything to interfere with or impair the intended
                                    operation of the Service.
                                </li>
                                <li>
                                    You can't attempt to create accounts or access or collect
                                    information in unauthorized ways.
                                </li>
                                <li>
                                    You can’t sell, license, or purchase any account or data
                                    obtained from us or our Service.
                                </li>
                                <li>
                                    You can't post someone else’s private or confidential
                                    information without permission or do anything that violates
                                    someone else's rights, including intellectual property rights
                                    (e.g., copyright infringement, trademark infringement,
                                    counterfeit, or pirated goods).
                                </li>
                                <li>
                                    You can’t modify, translate, create derivative works of, or
                                    reverse engineer our products or their components.
                                </li>
                                <li>
                                    You can't use a domain name or URL in your username without
                                    our prior written consent.
                                </li>
                            </ul>
                            <br/>
                            3.3. By accepting these terms, you also allow us to use the
                            content you upload, by granting us a non-exclusive, royalty-free,
                            transferable, sub-licensable, worldwide license to host, use,
                            distribute, modify, run, copy, publicly perform or display,
                            translate, and create derivative works of your content;
                            <br/>
                            3.4. We also retain the following rights:
                            <br/>
                            <ul>
                                <li>
                                    If you select a username or similar identifier for your
                                    account, we may change it if we believe it is appropriate or
                                    necessary (for example, if it infringes someone's intellectual
                                    property or impersonates another user).
                                </li>
                                <li>
                                    If you use content covered by intellectual property rights
                                    that we have and make available in our Service (for example,
                                    images, designs, videos, or sounds we provide that you add to
                                    content you create or share), we retain all rights to our
                                    content (but not yours).
                                </li>
                                <li>
                                    You can only use our intellectual property and trademarks, or
                                    similar marks as expressly permitted by our Brand Guidelines
                                    or with our prior written permission.
                                </li>
                                <li>
                                    You must obtain written permission from us or under an
                                    open-source license to modify, create derivative works of,
                                    decompile, or otherwise attempt to extract source code from
                                    us.
                                </li>
                            </ul>
                        </h1>

                        <h1 className="termsHeading">
                            4. INTELLECTUAL PROPERTY AND OTHER RIGHTS
                        </h1>
                        <h1 className="termsText">
                            4.1. By this Agreement, Blizzup agrees to grant you the right to
                            use the Services under a limited, non-exclusive, non-transferable
                            and revocable license. Blizzup is the owner of all rights to the
                            content of the Service, the use of any elements being prohibited
                            without our consent.
                        </h1>
                        <h1 className="termsText">
                            4.2. You also agree not to:
                            <ul>
                                <li>
                                    Use the Service for commercial purposes without our written
                                    consent;
                                </li>
                                <li>
                                    use applications to access, extract, index, reproduce, or
                                    otherwise steal the structure or information underlying the
                                    Service or its content;
                                </li>
                                <li>
                                    use the Service in any way that could disrupt or interfere in
                                    any way with the Service or the servers or networks connected
                                    to the Service;
                                </li>
                                <li>
                                    upload viruses or any malicious code that could affect the
                                    security of the Service;
                                </li>
                                <li>
                                    modify, adapt, sublicense, translate, sell, decompile or
                                    disassemble in any way any part of the Service or cause others
                                    to do so;
                                </li>
                                <li>
                                    modify, adapt, sublicense, translate, sell, decompile or
                                    disassemble in any way any part of the Service or cause others
                                    to do so;
                                </li>
                                <li>
                                    use or develop third-party applications that interact with the
                                    Service or other users without our written consent;
                                </li>
                                <li>
                                    use, access or publish the programming interface of the
                                    Service without our written consent;
                                </li>
                                <li>
                                    investigate, scan or test the vulnerability of our Service or
                                    any system or network.
                                </li>
                            </ul>
                        </h1>

                        <h1 className="termsHeading">5. LIMITATION OF LIABILITY</h1>
                        <h1 className="termsText">
                            5.1. Through the Service, we want to provide you with a suite of
                            features that you can enjoy without interruption, giving you an
                            easy-to-use interface. However, we would like to inform you of
                            certain situations for which we accept no liability:
                            <ul>
                                <li>
                                    Delays in providing the Service, failure to perform the
                                    Service, or failure to perform it due to the functionality of
                                    certain service providers, for which Blizzup makes no
                                    warranty;
                                </li>
                                <li>
                                    Delays in providing the Service, failure to perform the
                                    Service or its failure to perform due to technical issues.
                                </li>
                            </ul>
                        </h1>
                        <h1 className="termsText">
                            5.2. All direct damages arising out of this Agreement, as well as
                            any claims, actions, or indirect damages, are limited to the
                            amount of 1 RON. These limitations and exclusions apply even if
                            this remedy does not fully compensate you for the losses and even
                            if Blizzup knew or should have known about the possibility of
                            damages or the likelihood of their occurrence.
                        </h1>

                        <h1 className="termsHeading">6. SUSPEND OR CLOSE YOUR ACCOUNT</h1>
                        <h1 className="termsText">
                            6.1. The account is suspended or closed in the following cases:
                            <ul>
                                <li>At your request;</li>
                                <li>
                                    at the initiative of Blizzup, in the event of a breach of any
                                    provision of this Agreement;
                                </li>
                                <li>at the end of a 5-year period of inactivity;</li>
                                <li>any other justified reason.</li>
                            </ul>
                        </h1>
                        <h1 className="termsHeading">7. APPLICABLE LAW</h1>
                        <h1 className="termsText">
                            7.1. The contract will be interpreted in accordance with Romanian
                            law, the Romanian courts having the power to resolve any dispute.
                        </h1>

                        <h1 className="termsHeading">8. PAYMENT CLAUSE, REFUNDS</h1>
                        <h1 className="termsText">
                            8.1. Refunds will only be granted in the event that the event is cancelled
                            by the organizer or the venue. No refunds will be issued for any other reason.
                        </h1>
                        <h1 className="termsText">
                            8.2. If the event is cancelled, a refund of the full purchase price will be
                            issued to the purchaser within thirty (30) days of the cancellation.
                        </h1>
                        <h1 className="termsText">
                            8.3. If the purchaser cancels attendance or participation in the event,
                            for any reason whatsoever, no refund will be given.
                        </h1>
                        <h1 className="termsText">
                            8.4. In the event of a postponement or rescheduling of the event, the purchaser
                            will have the option to retain their ticket for the rescheduled event or request a refund.
                            If the purchaser chooses to retain their ticket, it will be valid for the rescheduled event
                            only.
                        </h1>
                        <h1 className="termsText">
                            8.5. If the purchaser is unable to attend the rescheduled event,
                            a refund will be issued only if the event is cancelled entirely.
                        </h1>
                        <h1 className="termsText">
                            8.6. Refunds will be issued using the same method of payment used for the original purchase.
                        </h1>

                        <h1 className="termsText">
                            8.7. Blizzup reserves the right to refuse a refund request if the purchaser has
                            violated any of the terms and conditions of the event.
                        </h1>
                        <h1 className="termsText">
                            8.8. In the event that the purchaser's payment is disputed or charged back by their
                            bank or credit card issuer, Blizzup reserves the right to cancel the purchaser's
                            ticket and refuse any future purchases from the purchaser.
                        </h1>
                        <h1 className="termsText">
                            8.9. Blizzup is not responsible for any expenses incurred by the purchaser in
                            relation to the cancelled or postponed event, including but not limited to travel,
                            accommodation, or other related costs.
                        </h1>
                        <h1 className="termsText">
                            8.10 Blizzup reserves the right to change the refund policy at any time,
                            without prior notice to the purchaser. Any such changes will be posted
                            on the event website or communicated to the purchaser via email.
                        </h1>

                        <h1 className="termsHeading">9. BUSINESS ACCOUNT UPGRADE AND SUBSCRIPTION PLANS</h1>
                        <h1 className="termsText">
                            By upgrading to the Business Plus or Business Premium
                            subscription plan in our app, Blizzup, business users gain access to
                            exclusive features and enhanced functionality. Please read these
                            terms and conditions carefully before proceeding with the upgrade.
                            By selecting the Business Plus or Business Premium plan, you
                            agree to the following terms:
                        </h1>
                        <h1 className="termsText">
                            9.1 Subscription Details:
                            a. Payment: The subscription fee for the Business Plus or Business
                            Premium plan will be charged to your designated payment method
                            on a recurring basis (monthly) according to the selected billing
                            cycle.
                            b. Automatic Renewal: The Business Plus or Business Premium
                            subscription will automatically renew at the end of each billing cycle
                            unless canceled by you before the renewal date.
                            c. Fee Changes: We reserve the right to modify the subscription
                            fees for the Business Plus or Business Premium plan. Any changes
                            in fees will be communicated to you in advance.
                        </h1>
                        <h1 className="termsText">
                            9.2 Account Upgrades and Downgrades:
                            a. Upgrading: You can upgrade your active Blizzup Business
                            account to the Business Plus or Business Premium plan at any time
                            during your subscription period. The upgraded plan's benefits will
                            be immediately available upon successful payment.
                            b. Downgrading: You may choose to downgrade from the Business
                            Premium plan to the Business Plus plan, or from the Business Plus
                            plan to the standard Business account, at the end of your current
                            billing cycle. Downgrading will result in the loss of certain features
                            available in the higher-tier plans.
                        </h1>
                        <h1 className="termsText">
                            9.3 Account Limitations:
                            <ul>
                                <li>
                                    a. Plan Accessibility: The upgraded Business Plus or Business
                                    Premium plans are exclusively available for use on your active
                                    Blizzup account that was used for the upgrade. These upgraded
                                    plans cannot be transferred or utilized on any other accounts that
                                    you may have created on Blizzup.
                                </li>
                                <li>
                                    b. Account Consistency: To enjoy the benefits of the Business Plus
                                    or Business Premium plans, you must consistently log in and
                                    access your Blizzup Business account using the same credentials
                                    used for the upgrade. Inconsistent account usage or attempts to
                                    circumvent this requirement may result in the suspension or
                                    termination of the upgraded plan.
                                </li>
                            </ul>

                        </h1>
                        <h1 className="termsText">
                            9.4 Plan Tracking and Cancellation:
                            <ul>
                                <li>
                                    a. App Store and Google Play Accounts: Once the Business Plus or
                                    Business Premium plans have been paid and activated, you can
                                    track and manage your subscription directly through your App Store
                                    or Google Play account that was signed in on your device during
                                    the plan acquisition.
                                </li>
                                <li>
                                    b. Cancellation: You have the option to cancel your Business Plus
                                    or Business Premium subscription through your respective App
                                    Store or Google Play account settings. It is your responsibility to
                                    initiate the cancellation before the renewal date to avoid additional
                                    charges.
                                </li>
                            </ul>

                        </h1>
                        <h1 className="termsText">
                            9.5 Renewal Grace Period:
                            <ul>
                                <li>
                                    a. In the event that the platform cannot successfully process the
                                    renewal payment for the Business Plus or Business Premium plan,
                                    you will enter a 16-day grace period starting from the date of the
                                    renewal. During this grace period, you will retain access to the
                                    Business Plus or Business Premium plan and its features.
                                </li>
                                <li>
                                    b. If the renewal payment issue remains unresolved after the 16-
                                    day grace period, the Plus or Premium plan will be automatically
                                    canceled, and the user's account will revert to the regular Business
                                    account. As a result, the following features associated with the Plus
                                    or Premium plan will be suspended immediately after the expiration
                                    of the grace period:
                                    <ul>
                                        <li>
                                            • For Business Plus:
                                            <ul>
                                                <li>
                                                    - Tickets: The user will not be able to continue selling tickets,
                                                    which may affect their ability to manage an active party where
                                                    ticket sales are ongoing.
                                                </li>
                                                <li>
                                                    - Crowdfund: The user will not be able to continue receiving
                                                    funds, which may affect their ability to sustain an active party
                                                    that relies on crowd funding.
                                                </li>
                                                <li>
                                                    - Lineup: The user will not be able to add more performers to the
                                                    lineup section in the party page.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            • For Business Premium:
                                            <ul>
                                                <li>
                                                    - Tickets: The user will not be able to continue selling tickets,
                                                    which may affect their ability to manage an active party where
                                                    ticket sales are ongoing.
                                                </li>
                                                <li>
                                                    - Crowdfund: The user will not be able to continue receiving
                                                    funds, which may affect their ability to sustain an active party
                                                    that relies on crowd funding.
                                                </li>
                                                <li>
                                                    - Lineup: The user will not be able to add more performers to the
                                                    lineup section in the party page.
                                                </li>
                                                <li>
                                                    - Map logo: The user will not have the ability to keep their
                                                    business logo on our map, and it will be replaced with a regular
                                                    placeholder.
                                                </li>
                                                <li>
                                                    - Subscriptions: The user will not be able to continue selling
                                                    subscriptions to their customers, and any active subscriptions
                                                    purchased from them will expire at the end of their subscription
                                                    period.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>


                                </li>
                            </ul>


                        </h1>
                        <h1 className="termsText">
                            9.6 Business Verification and Account Management:
                            a. Verification: The Business Plus and Business Premium plans are
                            available only to verified businesses. Our team verifies the
                            authenticity of business accounts before granting access to these
                            plans. If a business account fails to pass our verification process or
                            is found to be in violation of our terms of usage, we reserve the right
                            to cancel the plan without refund.
                            b. Account Communication: We may contact the person who
                            created the business account and upgraded to one of the plans to
                            verify information, manage situations, or validate the authenticity of
                            the business. If the contact person fail to respond to our
                            communication attempts, we reserve the right to cancel the plans
                            and, if necessary, suspend the business account due to suspicion of
                            violation.
                        </h1>
                        <h1 className="termsText">
                            9.7 Cancellation and Refunds:
                            <ul>
                                <li>
                                    a. Cancellation: You can cancel your Business Plus or Business
                                    Premium subscription by contacting our customer support team.
                                    Upon cancellation, your account will retain the upgraded features
                                    until the end of the current billing cycle.
                                </li>
                                <li>
                                    b. Refunds: Subscription fees for the Business Plus or Business
                                    Premium plan are non-refundable. No refunds will be issued for
                                    partial usage or unused portions of the subscription period.
                                </li>
                            </ul>
                        </h1>
                        <h1 className="termsText">
                            9.8 Modifications and Termination:
                            <ul>
                                <li>
                                    a. Modifications: We reserve the right to modify or discontinue any
                                    feature, functionality, or service associated with the Business Plus
                                    or Business Premium plan. Notice of any such modifications will be
                                    provided to you in advance.
                                </li>
                                <li>
                                    b. Termination: We reserve the right to terminate your Business
                                    Plus or Business Premium subscription in case of violation of our
                                    terms of service or any misuse of the app's features, as determined
                                    by our discretion.
                                </li>
                            </ul>


                        </h1>
                        <h1 className="termsText">
                            9.9 General Provisions:
                            <ul>
                                <li>
                                    a. Governing Law: These terms and conditions shall be governed
                                    by and construed in accordance with the laws of Romania.
                                </li>
                                <li>
                                    b. Severability: If any provision of these terms and conditions is held
                                    to be invalid or unenforceable, the remaining provisions shall
                                    remain in full force and effect.
                                </li>
                                <li>
                                    c. Entire Agreement: These terms and conditions constitute the
                                    entire agreement between you and Blizzup regarding the Business
                                    Plus and Business Premium subscription plans, superseding any
                                    prior agreements or understandings.
                                </li>
                                <li>
                                    d. Contact Information: For any questions or concerns regarding
                                    these terms and conditions or the Business Plus and Business
                                    Premium subscription plans, please contact our customer support
                                    team via support@blizzup.com.
                                </li>
                            </ul>
                            Please note that these terms and conditions are subject to change.
                            It is your responsibility to review and understand the latest terms
                            before proceeding with the upgrade to the Business Plus or
                            Business Premium plan.
                        </h1>

                        <h1 className="termsHeading"></h1>
                        <h1 className="termsText">Last updated: 27 MARCH 2023</h1>
                    </div>
                </div>
            </div>
        );
    }
}

export default Terms;
