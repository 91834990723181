import React from "react";
import Topbar from "../components/Topbar";
import SupportMail from "../links/SupportMail";

const Contact = () => {
  return (
    <div>
      <Topbar />
      <div className="contactContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Contact Us</h1>
          <h1 className="contactHeading">
            <SupportMail />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Contact;
