import React, { Component } from "react";
import { Link } from "react-router-dom";
import Topbar from "../components/Topbar";

class Help extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topbar />
        <div className="helpContainer">
          <div className="topTitleContainer">
            <h1 className="topTitle">Help Center</h1>
          </div>
          <div className="innerHelp">
            <div className="linkContainer">
              <Link to="/help/data" className="helpHeading">
                Your Data
              </Link>
              <p className="termsText">Retrieve, manage or delete your data.</p>
            </div>
            <div className="linkContainer">
              <Link to="/help/onboarding" className="helpHeading">
                Creating & attending a party
              </Link>
              <p className="termsText">Process and editing.</p>
            </div>
            <div className="linkContainer">
              <Link to="/help/tickets" className="helpHeading">
                Tickets
              </Link>
              <p className="termsText">Buy, store and transfer.</p>
            </div>
            <div className="linkContainer">
              <Link to="/help/payments" className="helpHeading">
                Payments & payouts
              </Link>
              <p className="termsText">Add, manage and create.</p>
            </div>
            <div className="linkContainer">
              <Link to="/help/earnings" className="helpHeading">
                Earnings
              </Link>
              <p className="termsText">Manage earnings.</p>
            </div>
            <div className="linkContainer">
              <Link to="/help/refunds" className="helpHeading">
                Refunds
              </Link>
              <p className="termsText">Manage refunds.</p>
            </div>
            <div className="linkContainer">
              <Link to="/help/taxes" className="helpHeading">
                Taxes
              </Link>
              <p className="termsText">Tax information.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Help;
