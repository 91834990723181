// import React, { Component } from "react";
// import Bottombar from "./Bottombar";

// class PrivacyPolicy extends Component {
//   render() {
//     return (
//       <div>
//       <div className="TermsC">
//       <h1 className="TermsTitle">Privacy Policy</h1>
//         <div className="InnerTerms">

//           <h1 className="TermsText">By registering and using BLIZZUP you will provide us, and we will collect, information
//           about you, including information used to identify you. This is what we call your “Personal Data”. This means data
//            that lets you identify yourself directly, like your family name, first name or your image, or indirectly such as
//            your password, phone number, or your browsing or session data. It is important for you to know how this
//            Data is collected, what we do with it and for how long, so you can be fully informed about your rights.
//            This is why we have prepared this document, which we have tried to make as clear and simple as possible.
//            If, despite our efforts, you would still like to ask us any questions please do not hesitate to contact us
//            using the contact details in section 10.</h1>

//            <h1 className="TermsText">This Privacy Policy is an integral part of our General Terms of Use for BLIZZUP.
//            It should therefore be read alongside our General Terms of Use.</h1>

//           <h1 className="TermsHeading">WHO IS RESPONSIBLE FOR PROCESSING YOUR PERSONAL DATA?</h1>
//           <h1 className="TermsText">It’s BLIZZUP. We are a Romanian company and our head office
//           is at Sos. Oltenitei 105A, 041303 Bucharest, Romania.</h1>
//           <h1 className="TermsText">BLIZZUP publishes the BLIZZUP application, a social network
//           that enables you to find events, create events, join events, follow your friends or unknown people and chat with them, post content which can be photos and videos.</h1>

//           <h1 className="TermsHeading">WHAT DATA DO WE COLLECT?</h1>
//           <h1 className="TermsText">In order to operate BLIZZUP, we collect the information (i) which you provide
//           to us directly, as well as (ii) data which we collect automatically.</h1>
//           <h1 className="TermsText">(i) Data you provide to us directly</h1>
//           <h1 className="TermsText">Registration data: in order to be able to use BLIZZUP, you must
//           create an account and provide us with the following personal details:</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Your mobile number (which is not shared with other users)</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Your name and pseudonym (both of which are visible to other users)</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Your email that will enable you to register on the application, verify your account, log into your account, change your account password, get notifications about your
//           activity on the app. Naturally,
//           this data is not visible to other users, and you should never share it with other users. Your email is
//           personal and secret. It must be your real email address. It is required to ensure the
//           security of your account.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Your date of birth: this information will not be provided to other users,
//           although they will be able to see your age. Minors under 13 are not permitted to use BLIZZUP.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • If you are a minor aged between 13 and 16, you may require permission
//           from your legal guardian in order to register on BLIZZUP.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Your gender: you can choose whether or not to specify you are male or female,
//           but you must tick one of the boxes. This information will not be visible to other users.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Your profile picture: the photo that you choose to use as your profile picture
//           must be of you. This will allow other users to identify you on BLIZZUP and allow us to ensure that people do not use
//           false profiles, which guarantees not only the security of the application, but your security too.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • The password that will enable you to log into your account. Naturally,
//           this data is not visible to other users, and you should never share it with other users. Your password is
//           personal and secret. It must contain at least 8 alphanumeric characters. It is required to ensure the
//           security of your account.</h1>
//           <h1 className="TermsText">All this information is mandatory in order for you to create your profile and to register on BLIZZUP.</h1>
//           <h1 className="TermsText">You may, if you so wish, complete other sections of your profile (profile bio,
//              social networks). This information is optional.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Data concerning your interaction with BLIZZUP: if you report a problem to us, request help or
//           exercise your rights, we will process the information you provide to us. In addition, in case of reasonable doubt as to
//           your age, we may ask you to verify your age and ask you for the following information;</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Copy of your ID document or school card (which is not shared with other users and is
//             immediately deleted after verification by our teams).</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • A short video of you pronouncing 3 random words (which is not shared with other users
//             and of which we only keep a screenshot after our teams have checked it).</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Data concerning your activity on BLIZZUP: when you use BLIZZUP, we can collect and process
//           information such as the date you register, the date you last logged in, your interactions with other users
//           (number of likes, comments, people you follow, people you invite to your personal event or other people events), your posts, events you have created, events where you joined,
//           public messages on the map created by you, comments on public messages on the map created by you or not.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Data concerning your location (geolocation): subject to your consent and the consent of your
//           legal guardian where required, we collect and process data concerning your location. This enables you,
//           depending on your settings, to include the town/city where you are located to create an event or public message on the map and suggest people, events and public messages on the map
//           in your town/city or around the world. If you no longer
//           wish us to know your location, simply untick the relevant box in your account settings. We will therefore no longer be able to display
//           your created event, public message on map to other people in the application.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Data concerning content: the photos and/or videos that you decide to upload to your profile
//           will be visible to all users. Be careful what you share with others and don’t forget that all users can save the content you
//           share and that we cannot do anything to stop this. When you are using the application, be aware that it is possible for other users to make
//           screenshots and screen-record (images and video) using the tools on their device, over which we have no control. The same goes for
//           the images and videos you post and comments you make via the BLIZZUP camera and chat service. The messaging service is available to users who are friends and/or
//           to unknown people.
//           Messages exchanged between users are private and can be forwarded to other users or third parties and in
//           the event of a judicial inquiry and/or in accordance with the laws and regulations in force.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Data concerning search preferences: you can configure your settings for privacy and discovery
//           settings by going to ‘Settings’ in your account.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Data concerning devices and hardware: we collect information from and on the devices and hardware you use
//           to access BLIZZUP such as the IP address, type of device, advertising identifier (e.g. Google’s AAID and Apple’s IDFA, both of which are
//           randomly-generated numbers that you can reset by going to your device settings), application bugs, version and language, operating system, etc.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Device address book: as BLIZZUP is an application for creating and finding events and making and staying in touch with friends, you are
//           permitted to identify your contacts who are already registered on BLIZZUP and to follow them, if you so wish, on the application.
//           You can also choose to send a message to your contacts who are not on BLIZZUP to ask them to join you on the app. In order for us to provide
//           this service, we need access to your address book, which we will use to collect data for the above purpose only. We will never add your contacts
//           without your permission, or send them any spam.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Cameras, microphone and photos: in order to be able to send and upload your photos from your camera,
//           you must give us permission to access them.</h1>

//           <h1 className="TermsHeading">WHAT ARE THE LEGAL REASONS WHY WE COLLECT DATA?</h1>
//           <h1 className="TermsText">When we collect and process your Personal Data, we may do so for the following reasons:</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To enable you to access BLIZZUP and use the services we provide: this actually means that you are performing the
//           contract we have with you, which corresponds to our General Terms of Use, which includes this Privacy Policy. For example, when you use BLIZZUP,
//           we use your data to operate your account, make your profile accessible, suggest events, create events, create public messages on the map,
//           suggest people, send your messages and the content you share, and
//           sell the paid tickets of your created event.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • In the legitimate interests of BLIZZUP: we may use your data if we have a legitimate reason to do so.
//           For example, we process data for administrative purposes, to guard against fraudulent and illegal conduct (e.g.: false age), and to ensure
//           that the application remains secure.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To fulfil a legal obligation: we are required, for example, to keep data identifying new content and
//           to forward it to the authorities if requested to do so.</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • With your consent or that of your legal guardian: while we process data with your consent,
//           you may withdraw such consent at any time by going to ‘Settings’.</h1>

//           <h1 className="TermsHeading">HOW DO WE USE THE DATA WE COLLECT?</h1>
//           <h1 className="TermsText">We collect, use and store your Personal Data in order to process your requests, manage our relationship and your use of BLIZZUP,
//           optimize our services and tools in order to create and maintain a secure environment, and fulfill our legal obligations, as explained below.</h1>
//           <h1 className="TermsText">Managing our relationship and access to BLIZZUP</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To create and manage your user account (including access to BLIZZUP services and Help)</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Geolocation to put you in touch with events, public messages on the map, posts, people nearby and creating your own event and public message on the map</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To manage and store messages exchanged between users</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Proposition: To manage and store events, public messages on the map, photos, videos, comments and messages exchanged between users</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • Where appropriate, to show you offers, content and adverts we think you’ll like</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To send information message concerning BLIZZUP services and your interactions with other users (Notifications)</h1>
//           <h1 className="TermsText">Security and fulfilment of legal and contractual obligations</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To respond to requests from administrative and judicial authorities, law enforcement officials and legal officers, and to abide by court decisions;</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To identify and prevent fraudulent activity and threats to the security of BLIZZUP pursuant to and in accordance with the law;</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To analyse reports concerning users who have breached our Terms of Use and/or the regulations in force;</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To improve the safety and security of our services;</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To use information collected via cookies or any other technology to improve our services and your user experience.
//           You can read our Cookies Policy by clicking here;</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To enforce our General Terms of Use and this Privacy Policy;</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • To counter fraud (for example related to age), confirm your identity, and fight against illegal activity.</h1>

//           <h1 className="TermsHeading">HOW LONG DO WE KEEP YOUR DATA FOR?</h1>
//           <h1 className="TermsText">You can delete your account at any time by clicking on the relevant button in your ‘Settings’. When you delete your account, you will immediately
//           stop communicating your current and future details to BLIZZUP and your profile will no longer be accessible.</h1>
//           <h1 className="TermsText">We will only keep your Personal Data for the period of time required to fulfil the purposes for which we process your details unless we are
//           permitted or required by law to keep it for a longer period of time. Generally, we will delete your Data as soon as you delete your account, unless:</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • We are required to retain it as proof that we comply with the legislation in force (e.g. recordings of consent to our Terms of Use,
//             Privacy Policy, and other similar consents are retained for five years);</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • We are required, due to an unresolved issue, claim or dispute, to retain relevant information until the matter has been resolved; or</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • If the data must be retained in our legitimate commercial interests, for example in order to prevent fraud and improve users’ safety and security.
//           For example, we may be required to retain data in order to prevent a user who has been banned from opening a new account due to their dangerous conduct or a security incident.</h1>
//           <h1 className="TermsText">If your age is checked, we immediately delete your ID document or school card after verification and only keep a screenshot from the
//           short video that you will have sent us.</h1>

//           <h1 className="TermsHeading">WHO HAS ACCESS TO THE DATA WE COLLECT?</h1>
//           <h1 className="TermsText">The data we collect is intended solely for internal use by BLIZZUP and our partners and service providers.</h1>
//           <h1 className="TermsText">Only certain BLIZZUP authorized managers and employees will process your Personal Data for the above
//           purposes, and they are only permitted to access the data strictly required in order to carry out their work. BLIZZUP may also enter
//           into an agreement with authenticated and reliable service providers and partners who may access and/or process users’ personal data and
//           who guarantee that such data will remain secure and confidential in accordance with this Policy.</h1>
//           <h1 className="TermsText">These service providers and partners help us to operate and improve BLIZZUP, particularly in relation to the hosting of
//           data, studies, analyses, and statistics, the use and/or operation of the application, targeted advertising, the fight against fraud linked to
//           the age of the user, and the assistance required by users.</h1>
//           <h1 className="TermsText">Thus, the ability to integrate Spotify and Uber into your own created events leads us to use Spotify and Uber features and share information
//           with Spotify and Uber. If you would like to know more about how Spotify and Uber processes data, we recommend that you review the Spotify and Uber Terms of Services.</h1>
//           <h1 className="TermsText">Lastly, the data we process may be forwarded to the police and to the judicial and administrative authorities
//           in order for us to fulfil our legal obligations.</h1>

//           <h1 className="TermsHeading">WILL YOUR DATA BE TRANSFERRED OUTSIDE THE EUROPEAN UNION?</h1>
//           <h1 className="TermsText">In order for your Personal Data to be processed in accordance with this Privacy Policy, such data may be transferred to one or
//           more other countries that may or may not be within the European Economic Area, including in particular the United States.</h1>
//           <h1 className="TermsText">We guarantee that such transfers are carried out in such a way as to ensure that the data remains
//           confidential and secure and that it is adequately protected in accordance with the regulations in force for the protection of personal data.
//           In this case, we offer guarantees provided by current regulations, such as standard contractual clauses approved by the European Commission,
//           which represent commitments made between companies that transfer Personal Data in order to ensure that such data remains confidential and secure.</h1>

//           <h1 className="TermsHeading">HOW IS YOUR DATA PROTECTED?</h1>
//           <h1 className="TermsText">BLIZZUP will ensure that your data is processed securely and confidentially, including in cases where certain operations
//           are carried out by partners. Accordingly, appropriate technical and organizational measures are implemented to prevent the loss, misuse, alteration and
//           erasure of your Personal Data.</h1>
//           <h1 className="TermsText">Nevertheless, there is no ‘zero risk’ on the internet and we cannot guarantee the security of your Personal Data 100%.</h1>
//           <h1 className="TermsText">That’s why it is important to let us know right away if you think you have been a victim of an attack on your
//           Personal Data, by writing to us here.</h1>

//           <h1 className="TermsHeading">YOUR RIGHTS</h1>
//           <h1 className="TermsText">You have the right to access your Personal Data and to request that it be corrected, completed or updated. You may also
//           request that your Data be deleted (or you can delete your account in the “Settings” section of the application), refuse the processing of your Data,
//            or request that it be limited within the terms of current legislation. You may ask to exercise your right to data portability; in other words, your
//            right to receive your Personal Data in a frequently used structured format and your right to send such data to another processor. As indicated in
//            Section 3 above, your data is processed with your consent and you can withdraw your consent at any time by going to “Settings”.</h1>
//           <h1 className="TermsText">You also have the right to issue instructions as to how your Personal Data should be used after you die. In order to
//           exercise your rights, you will be asked to produce proof of your identity of any kind. You may submit your request by sending a letter or
//           an e-mail to privacy@blizzup.com . We will do our utmost to fulfil your requests, but don’t forget that this
//           right is not unlimited and must be exercised according to the conditions of current legislation. Any request to exercise your rights should
//           be reasonable. Where necessary, we will explain the reasons why we are unable to meet your request.</h1>
//           <h1 className="TermsText">Should you have any other complaint, please contact the appropriate national data protection authority.</h1>

//           <h1 className="TermsHeading">CONTACT</h1>
//           <h1 className="TermsText">Should you have any questions concerning this Privacy Policy or requests concerning your Personal Data, you can contact us:</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • By completing the online contact form on the BLIZZUP website (www.blizzup.com/contact);</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • By sending an e-mail to privacy@blizzup.com; or</h1>
//           <h1 className="TermsText" style={{marginLeft: 20}}>  • By writing to us at the following postal address: BLIZZUP –  Sos. Oltenitei 105A, 041303 Bucharest, Romania.</h1>

//           <h1 className="TermsHeading">Updates to our Privacy Policy</h1>
//           <h1 className="TermsText">It may be necessary to update this Privacy Policy, in particular for us to comply with current regulations, or
//           in case of changes to the way we use your Personal Data.</h1>
//           <h1 className="TermsText">You will be informed in advance of any modifications or updates by any appropriate means, in particular messages displayed
//           on the BLIZZUP application, or by email. These changes may require your confirmation in some cases. In any event, continuing to useBLIZZUP implies full
//           acceptance of the changes made to this Privacy Policy.</h1>
//           <h1 className="TermsText">Last updated: 15 July 2020</h1>

//         </div>
//       </div>
//       <Bottombar />
//       </div>
//     );
//   }
// }

// export default PrivacyPolicy;

import React, { Component } from "react";
import Topbar2 from "../components/Topbar2";

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topbar2 />
        <div className="termsContainer">
          <h1 className="termsTitle">Privacy Policy</h1>
          <p className="info2">Last updated June 12, 2022</p>
          <p className="info1">Thank you for using Blizzup!</p>
          <div className="innerTerms">
            <h1 className="termsText">
              <p>
                Blizzup is aware of the importance of your data and is committed
                to protecting your privacy and security. Therefore, it is
                important for us to provide you in a transparent manner the
                information regarding the processing of your personal data under
                this Personal Data Processing Policy, applicable for the
                purposes and in the situations set out below.
              </p>
              <p>
                By creating an account on the Blizzup application, accepting the
                provisions of the Terms and Conditions and accepting this Policy
                for the processing of personal data, you acknowledge and agree
                to the processing described in this document.
              </p>
            </h1>
            <h1 className="termsHeading">1. ABOUT US</h1>
            <h1 className="termsText">
              1.1 Blizzup is a social media service that aims to connect people
              with cultural events of their choice and to allow them to share
              photos and videos in a safe and friendly environment. The services
              we offer are:
              <ul>
                <li>
                  Possibility to create a personalized account within the
                  Service;
                </li>
                <li>
                  Features that allow you to upload and share videos and photos
                  of your choice;
                </li>
                <li>
                  Connecting you with the clubs, parties and cultural events of
                  your choice;
                </li>
                <li>
                  Interacting with people that share similar interest and/or
                  events;
                </li>
              </ul>
            </h1>

            <h1 className="termsHeading">2. WHAT INFORMATION DO WE PROCESS?</h1>
            <h1 className="termsText">
              2.1 In order to provide our services, we process the following
              categories of personal data of users:
              <ul>
                <li>Email, for creating an account in the platform;</li>
                <li>
                  phone number, in order to grant you access to the service
                </li>
                <li>password, for access on the Blizzup platform;</li>
                <li>age</li>
                <li>sex</li>
                <li>birthday</li>
              </ul>
            </h1>
            <h1 className="termsText">
              2.2 In order to improve the services offered by the Blizzup
              application:
              <ul>
                <li>
                  We store data related to visiting sessions for the purpose of
                  telemetry and troubleshooting in the application.
                </li>
              </ul>
            </h1>
            <h1 className="termsText">
              2.3 For the purpose of marketing communications:
              <ul>
                <li>email</li>
                <li>phone number</li>
                <li>name</li>
              </ul>
            </h1>
            <h1 className="termsText">
              2.4 For the purposes determined by the legislation in force, in
              connection with the activities mentioned above for the related
              archiving obligations in order to maintain records related to the
              activities carried out, to protect the rights in court and to
              exercise other rights according to the law and concluded
              contracts, the fulfillment of possible archiving requirements , in
              accordance with legal provisions.
            </h1>
            <h1 className="termsText">
              2.5 We may also use all the data you upload for the purpose of
              improving the service and/or creating new functionalities.
            </h1>

            <h1 className="termsHeading">
              3. TO WHOM DO WE DISCLOSE YOUR PERSONAL DATA?
            </h1>
            <h1 className="termsText">
              3.1 In order to provide Blizzup services, we may transfer your
              data to third parties, including Facebook, that may collect or
              receive information from your app and other apps and use that
              information to provide measurement services and targeted ads.
            </h1>
            <h1 className="termsText">
              3.2 In order to fulfill the legal obligations:
              <ul>
                <li>
                  we may transmit your data to public authorities (Prosecutor's
                  Office, Police, courts and other competent state bodies), on
                  the basis and within the limits of legal provisions and as a
                  result of expressly formulated requests.
                </li>
              </ul>
            </h1>
            <h1 className="termsText">
              3.3 Personal data processed by Blizzup is not sold to third
              parties or transferred outside the European Union.
            </h1>

            <h1 className="termsHeading">
              4. WHAT RIGHTS DO YOU HAVE REGARDING THE PROCESSING OF YOUR DATA?
            </h1>
            <h1 className="termsText">
              4.1 By reading this Policy, you acknowledge that your rights under
              the applicable law are guaranteed, namely:
              <ul>
                <li>
                  the right to be informed about the processing of your personal
                  data;{" "}
                </li>
                <li>the right to access your personal data;</li>
                <li>the right to rectify your personal data;</li>
                <li>
                  the right to delete (“the right to be forgotten”) your
                  personal data;
                </li>
                <li>the right to obtain the restriction of processing;</li>
                <li>the right to obtain the restriction of processing;</li>
                <li>the right to data portability;</li>
                <li>
                  the right to object to the processing of personal data
                  concerning you and to request the rectification, updating or
                  deletion of data in accordance with the law - in the case of
                  personal data processed for direct marketing purposes, this
                  right can be exercised at any time, free of charge and without
                  justification;
                </li>
                <li>
                  the right not to be subject to an individual automatic
                  decision, including the creation of profile;
                </li>
                <li>
                  to submit a complaint to the National Authority for the
                  Supervision of Personal Data Processing www.dataprotection.ro;
                </li>
              </ul>
            </h1>
            <h1 className="termsText">
              4.2 You can exercise these rights by accessing the app settings,
              by sending an e-mail to
              {/* <PrivacyMail />  */}
              or by accessing the contact form at
              {/* <Link to="/help/data"> */}
              Blizzup data center
              {/* </Link> */}
            </h1>

            <h1 className="termsHeading">
              5. COLLECTION OF PERSONAL DATA OF MINORS.
            </h1>
            <h1 className="termsText">
              5.1 Blizzup does not collect or process personal data of minors.
              We also do not carry out direct marketing promotional activities
              for minors.
            </h1>
            <h1 className="termsText">
              5.2 Minors who have reached the age of 16 may purchase products or
              services and request and receive communications from Blizzup only
              if they have the consent of their legal representative or
              guardian, as required by law.
            </h1>
            <h1 className="termsText">
              5.3 By reading and accepting the provisions of this policy, any
              person who provides us with personal data through the site or
              other applications or devices covered by this Policy guarantees
              that he is of at least 18 years old and has full capacity to
              exercise.
            </h1>
            <h1 className="termsText">
              5.4 Any collection or processing of personal data of minors will
              be carried out only in accordance with the law.
            </h1>

            <h1 className="termsHeading">
              6. HOW LONG DO WE PROCESS YOUR DATA?
            </h1>
            <h1 className="termsText">
              6.1 Your data will be kept for as long as you have an active
              account on our platform;
            </h1>
            <h1 className="termsText">
              6.2 In order to protect the security and safety of our users'
              information, we have implemented a data retention period of three
              months from the time of account deletion. During this period,
              account information will be retained, although the account will no
              longer be visible on our platform;
            </h1>
            <h1 className="termsText">
              6.3 We will also delete your information after 1 year of
              continuous inactivity, unless:
              <ul>
                <li>
                  we need to keep the information in order to comply with
                  applicable law (for example, some “traffic data” is kept for
                  one year in order to comply with legal data retention
                  obligations);
                </li>
                <li>
                  we must keep them in order to prove compliance with applicable
                  law (for example, records of consents to our Terms, Privacy
                  Policy and other similar consents are kept for five years);
                </li>
                <li>
                  there is a problem, a complaint or an unresolved dispute that
                  requires us to keep the relevant information until it is
                  resolved; or
                </li>
                <li>
                  information must be kept for our legitimate interests, such as
                  preventing fraud and improving the safety and security of
                  users. For example, the retention of information may be
                  necessary to prevent a new account from being opened by a user
                  who has been excluded due to dangerous behavior or security
                  incident;
                </li>
                <li>
                  at the same time, the storage of personal data for a longer
                  period of time can be done to compile statistics, improve
                  services, manage customer accounts, research/market research.
                </li>
              </ul>
            </h1>

            <h1 className="termsHeading">7. INFORMATION SECURITY</h1>
            <h1 className="termsText">
              7.1 We make every effort to protect the information we collect
              against unauthorized access, alteration, disclosure or
              destruction. As with all technology companies, although we take
              steps to secure the information, we cannot guarantee that the
              information will always remain secure.
            </h1>
            <h1 className="termsText">
              7.2 We regularly monitor systems for possible vulnerabilities and
              attacks and regularly review our information collection, storage
              and processing practices to update our physical, technical and
              organizational security measures.
            </h1>
            <h1 className="termsText">
              7.3 We may suspend the use of all or part of the Services without
              prior notice if we suspect or detect a breach of security. If you
              have any doubts about the security of your account or information,
              please let us know immediately.
            </h1>
            <h1 className="termsText">
              Version 22.1, Last updated: 20 May 2022
            </h1>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
