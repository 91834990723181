import React from "react";
import {isIOS, isMobile, isAndroid } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
const applogo = require("../images/applogo.png");
const qr = require("../images/qr.jpeg");
const TikTok = () => {

    const navigate = useNavigate();
    React.useEffect(() => {

        if(isMobile) {
            if(isAndroid) {
                window.location.replace('https://play.google.com/store/apps/details?id=com.blizzup.android');
            }
            if(isIOS) {
                // window.location.replace('https://blizzup.com/qr-connect.png');
            }
        } else {
            navigate('/', {
                replace: true
            });
        }
    },[])

    return (
        <div style={{
            width:'100%',
            height:'100%',
            minHeight:'100vh',
            backgroundColor:'#151515'
        }}>
            <div className="inner1">
                <img src={applogo} className="appLogo" />
                <p className="title1">
                   Long press on QR to start the party
                </p>
                <img src={qr} className="qr" className="appLogo" width={200} height={200}/>
            </div>

        </div>
    );
};

export default TikTok;
