import React from "react";

const privacyEmailAddress = process.env.REACT_APP_MAIL_PRIVACY;

const PrivacyMail = () => {
  return (
    <a className="link" href={`MAILTO:${privacyEmailAddress}`}>
      {privacyEmailAddress}
    </a>
  );
};

export default PrivacyMail;
