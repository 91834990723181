import React from "react";

const supportAddress = process.env.REACT_APP_MAIL_SUPPORT;

const SupportMail = () => {
  return (
    <a href={`MAILTO:${supportAddress}`}>
      <h1 className="mail-no-underline contactHeading mail-no-underline">
        {supportAddress}
      </h1>
    </a>
  );
};

export default SupportMail;
