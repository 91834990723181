import React, { Component } from "react";
import Topbar2 from "../components/Topbar2";

const logo = require("../images/Topbar_logo2.png");

class DeletedAccount extends Component {
  render() {
    return (
      <div>
        <Topbar2 />
        <div className="inner4">
          <img src={logo} className="verifiedLogo" />
          <h1 className="termsTitle">Your accout has been deleted!</h1>
          <h1 className="termsText">We hope to see you again soon</h1>
        </div>
      </div>
    );
  }
}

export default DeletedAccount;
