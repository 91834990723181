import React, { Component } from "react";
import Topbar2 from "../components/Topbar2";

const verified_logo = require("../images/Topbar_logo2.png");

class Verified extends Component {
  render() {
    return (
      <div>
        <Topbar2/>
        <div className="inner4">
          <img src={verified_logo} className="verifiedLogo" />
          <h1 className="termsTitle">Your accout has been verified!</h1>
          <h1 className="termsText">
            Thank you for verifying your Blizzup account!
          </h1>
        </div>
      </div>
    );
  }
}

export default Verified;
