import React from "react";
import Topbar from "../../components/Topbar";

const Tickets = () => {
  return (
    <div>
      <Topbar />
      <div className="termsContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Tickets</h1>
          <p className="info3">
            This is how you can buy, store and transfer tickets.
          </p>
        </div>
        <div className="innerTerms">
          <h1 className="termsHeading">Buy ticket</h1>
          <p className="termsText">
            You can buy a ticket by navigating to a party page and press
            <b> {"Buy Ticket"}</b> button from the bottom of the screen or
            select a ticket from <b> {"Tickets"}</b> list.
          </p>
          <h1 className="termsHeading">Store ticket</h1>
          <p className="termsText">
            After purchasing a ticket, it is automatically saved in
            <b> {" Profile > Wallet (wallet icon) > My Tickets or Settings > Joining & hosting > My tickets"}</b>.
          </p>
          <h1 className="termsHeading">Transfer ticket</h1>
          <p className="termsText">
            You can transfer a ticket by navigating to
            <b>{" Profile > Wallet (wallet icon) > My Tickets > Ticket transfer (arrows icon top-right corner) or Settings > Joining & hosting > Ticket transfers."}</b> and
            pressing
            <b> {"Transfer"}</b> button on the ticket you want to transfer.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
