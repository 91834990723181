import React, { Component } from "react";
import Topbar2 from "../components/Topbar2";

class SupportForm extends Component {
  render() {
    return (
      <div>
        <Topbar2 />
        <div className="firstScreenContainer">
          <h1 className="termsTitle">Submit your request</h1>
          <div className="inner3">
            <h1 className="nameHeading">Name</h1>
            <input
              type="text"
              placeholder="Your name..."
              className="inputName"
            />
            <h1 className="emailHeading">Email</h1>
            <input
              type="email"
              placeholder="Your email..."
              className="inputEmail"
            />
            <h1 className="descriptionHeading">Description</h1>
            <textarea
              name="textarea"
              placeholder="Tell us more..."
              className="inputDescription"
            ></textarea>
            <h1 className="descriptionInfo">
              Please enter the details of your request. A member of our support
              staff will respond as soon as possible.
            </h1>
            <button className="btn submit">Submit</button>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportForm;
