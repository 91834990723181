import React, { Component } from "react";
import Topbar from "../components/Topbar";

class CommunityGuidelines extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topbar />
        <div className="termsContainer">
          <div className="topTitleContainer">
            <h1 className="topTitle">Community Guidelines</h1>
            <p className="info2">Last updated December 15, 2022</p>
          </div>
          <div className="innerTerms">
            <h1 className="termsText">
              <p>
                Blizzup is about people, friends, beautiful moments, memories
                and of course amazing parties. We, the team, are part of the
                community as people who love to attend parties with friends. The
                community is the reason why we developed this app and we want to
                offer services that can help members connect more easily and
                create beautiful and unforgettable moments. For this purpose, we
                want the activity in the Blizzup app to be a pleasant one,
                that's why we created the community guidelines. There is no
                party if things get out of hand and we have the right to decide
                what content and actions violate the welfare of the community
                and therefore will not be allowed on the platform.
              </p>
            </h1>
            <h1 className="termsHeading">Be yourself</h1>
            <h1 className="termsText">
              Be you. Use your real name and a picture of yourself and it will
              be easier for you to find your friends and meet new people. There
              is nothing cool about pretending to be someone else. One more
              thing, as a host, it will be much more credible that the party you
              are organizing is legitimate.
            </h1>

            <h1 className="termsHeading">Intolerant behavior</h1>
            <h1 className="termsHeading2">Harassment</h1>
            <h1 className="termsText">
              We prohibit any kind of harassment. Do not bully people, do not
              disclose their personal information, do not threaten, do not try
              to use the property of others, do not attack in any way. Respect
              everyone's rights.
            </h1>

            <h1 className="termsHeading2">Violence, threats, harm</h1>
            <h1 className="termsText">
              Violence, threats and harm are prohibited in any form, both
              personal and to groups. We also do not accept this behavior on
              animals.
            </h1>

            <h1 className="termsHeading2">
              Dangerous misinformation and disinformation
            </h1>
            <h1 className="termsText">
              Do not share misinformation and disinformation that can cause
              physical and mental harm. Do not try to manipulate, instigate or
              endanger the lives of others by doing so.
            </h1>

            <h1 className="termsHeading2">Illegal & harmful content</h1>
            <h1 className="termsText">
              Blizzup prohibits any form of illegal activity. Do not promote
              such activities as well as the sale of illegal objects.
            </h1>

            <h1 className="termsHeading2">Terrorism or violent groups</h1>
            <h1 className="termsText">
              We prohibit any form of terrorist activity, both personal and in
              groups. Don't use your Blizzup account to promote such activities.
            </h1>

            <h1 className="termsHeading2">Nudity</h1>
            <h1 className="termsText">
              It is not necessary. We prohibit nudity or pornography.
            </h1>

            <h1 className="termsHeading2">Spam, scams, false reporting</h1>
            <h1 className="termsText">
              We prohibit spam, scams and false reporting of any kind. Do not
              try to do this with regard to possible or future events, meetings,
              exchanges of goods or personal information.
            </h1>
          </div>
        </div>
      </div>
    );
  }
}

export default CommunityGuidelines;
