import React from "react";
import Topbar from "../../components/Topbar";

const Payments = () => {
  return (
    <div>
      <Topbar />
      <div className="termsContainer">
        <div className="topTitleContainer">
          <h1 className="topTitle">Payments & payouts</h1>
          <p className="info3">
            This is how you can add and manage a payment method and create a
            payout.
          </p>
        </div>
        <div className="innerTerms">
          <h1 className="termsHeading">Add payment method</h1>
          <p className="termsText">
            You can add a payment method by navigating to
            <b> {" Profile > Settings > Payments > Payment Method "}</b> and
            pressing
            <b> {" Add Payment Method "}</b> button from the top of the screen.
          </p>
          <h1 className="termsHeading">Manage payment method</h1>
          <p className="termsText">
            You can set your primary payment method by navigating to
            <b> {" Profile > Settings > Payments > Payment Method "}</b> and
            selecting the one you prefer. Also you can remove a payment method
            by pressing
            <b> {" Remove Card "}</b> button from the specific payment method.
          </p>
          <h1 className="termsHeading">Create payout</h1>
          <p className="termsText">
            You can create a payout by navigating to
            <b>
              {" "}
              {" Profile > Settings > Payments > Payout Informations "}
            </b>{" "}
            and pressing
            <b> {" Add Payout Method "}</b> button from the top of the screen.
          </p>
          <p className="stripeText">
            Payments and payouts are secured by Stripe.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Payments;
