import React, { Component } from "react";

const screenshotImage1 = require("../images/image1.png");
const screenshotImage2 = require("../images/image2.png");
const screenshotImage3 = require("../images/image3.png");
const applogo = require("../images/applogo.png");
const appstore = require("../images/AppstoreBadge.png");
const googleplay = require("../images/GooglePlayBadge.png");

class FirstScreen extends Component {
  render() {
    return (
      <div className="firstScreenContainer">
        <div className="inner1">
          <img src={applogo} className="appLogo" />
          <p className="title1">
            Your party,
            <br />
            your friends
          </p>
          <p className="description1">
            Party with your friends and meet new people, anytime, anywhere.
            {/* <br /> */}
          </p>
          <div className="badgeContainer">
            <a
              onClick={(e) => {
                e.preventDefault();
                window.fbq("track", "SubmitApplication");
                window.location.replace(
                  "https://apps.apple.com/ro/app/blizzup-make-friends-party/id1613937164"
                );
              }}
              href={
                "https://apps.apple.com/ro/app/blizzup-make-friends-party/id1613937164"
              }
            >
              <img src={appstore} className="AppstoreBadge" />
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                window.fbq("track", "SubmitApplication");
                window.location.replace(
                  "https://play.google.com/store/apps/details?id=com.blizzup.android"
                );
              }}
              href={
                "https://play.google.com/store/apps/details?id=com.blizzup.android"
              }
            >
              <img src={googleplay} className="GooglePlayBadge" />
            </a>
          </div>
        </div>
        <div className="outer2">
          <div className="inner2">
            <div className="titleContainer">
              <p className="title2">Parties</p>
              <p className="description3">
                Explore all types of parties where you can go or create one
                right at your place.
              </p>
            </div>
            <img src={screenshotImage1} className="screenshot1" />
          </div>
        </div>

        <div className="inner2reverse">
          <div className="titleContainer">
            <p className="title4">Squads</p>
            <p className="description2">
              Invite your friends and make a squad. Go to parties together, make
              new friends, and keep memories on your squad page.
            </p>
          </div>
          <img src={screenshotImage2} className="screenshot1" />
        </div>
        <div className="outer2">
          <div className="inner2">
            <div className="titleContainer">
              <p className="title5">Shots</p>
              <p className="description3">
                Shot your memories. Share pictures and videos of what happens at
                the parties you go to.
              </p>
            </div>
            <img src={screenshotImage3} className="screenshot1" />
          </div>
        </div>
      </div>
    );
  }
}

export default FirstScreen;
