import React, { Component } from "react";
import { Link } from "react-router-dom";
const applogo = require("../images/Topbar_logo2.png");
const appstore = require("../images/AppstoreBadge.png");
const googleplay = require("../images/GooglePlayBadge.png");
const facebookLogo = require("../images/facebookLogo.png");
const instagramLogo = require("../images/instagramLogo.png");
const tiktokLogo = require("../images/tiktokLogo.png");
const twitterLogo = require("../images/twitterLogo.png");

class Bottombar extends Component {
  render() {
    return (
      <div className="bottomBarContainer">
        <div className="bottomBarCategoryContainer2">
          <img src={applogo} className="appLogo2" />
          <div className="badgeContainer">
            <a
              onClick={(e) => {
                e.preventDefault();
                window.fbq("track", "SubmitApplication");
                window.location.replace(
                  "https://apps.apple.com/ro/app/blizzup-make-friends-party/id1613937164"
                );
              }}
              href={process.env.REACT_APP_APP_STORE_LINK}
              className="storeLink"
            >
              <img src={appstore} className="AppstoreBadge2" />
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                window.fbq("track", "SubmitApplication");
                window.location.replace(
                  "https://play.google.com/store/apps/details?id=com.blizzup.android"
                );
              }}
              href={process.env.REACT_APP_GOOGLE_PLAY_LINK}
              className="storeLink"
            >
              <img src={googleplay} className="GooglePlayBadge2" />
            </a>
          </div>
        </div>
        <div className="bottomBarCategoryContainerOuter">
          <div className="bottomBarCategoryContainer">
            <p className="bottomBarCategoryTitle">Stay in touch</p>
            <Link to="/contact" className="categoryText">
              Contact Us
            </Link>
            <div className="bottomBarCategoryContainerSocial">
              <p className="bottomBarCategoryTitle">Follow us</p>
              <div className="bottomBarCategoryContainerSocialInner">
                <div className="badgeContainer2">
                  <a
                    href="https://www.instagram.com/blizzupofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="storeLink"
                  >
                    <img src={instagramLogo} className="socialLinkIcon" />
                  </a>
                  <a
                    href="https://www.facebook.com/blizzup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="storeLink"
                  >
                    <img
                      src={facebookLogo}
                      target="_blank"
                      alt=""
                      rel="noopener noreferrer"
                      className="socialLinkIcon"
                    />
                  </a>
                  <a
                    href="https://vm.tiktok.com/ZMNN1Hp73/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="storeLink"
                  >
                    <img src={tiktokLogo} alt="" className="socialLinkIcon" />
                  </a>
                  <a
                    href="https://mobile.twitter.com/blizzupofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="storeLink"
                  >
                    <img src={twitterLogo} alt="" className="socialLinkIcon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomBarCategoryContainer">
            <p className="bottomBarCategoryTitle">More</p>
            <Link to="/terms" className="categoryText">
              Terms
            </Link>
            <Link to="/privacy-policy" className="categoryText">
              Privacy Policy
            </Link>
            <Link to="/help" className="categoryText">
              Help Center
            </Link>
            <Link to="/community-guidelines" className="categoryText">
              Community Guidelines
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Bottombar;
